import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
    const [hora, setHora] = useState('');

    useEffect(() => {
        const fetchTime = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/hora`;
                const response = await axios.get(apiUrl);
                setHora(response.data.hora);  // Asegúrate de que la respuesta tenga el formato correcto
            } catch (error) {
                console.error('Error fetching time:', error);
            }
        };
	fetchTime();
    }, []);

    return (
        <div className="App">
            <h1>Current Time:</h1>
            <p>{hora}</p>
        </div>
    );
}

export default App;

